import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const urltest=[
  "https://mui.com/static/images/cards/contemplative-reptile.jpg",
  "https://mui.com/static/images/cards/paella.jpg",
  "https://mui.com/static/images/cards/live-from-space.jpg"]
const images = [
  {
    url: urltest[0],
    title: 'Ortodoncia',
    width: '100%',
  }
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height:"7vw",
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 80,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    transform:'scale(1.1)',
    '& .MuiImageBackdrop-root': {
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    }
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: "#235347", //#702670  #235347
  opacity: 0.85,
  transition: theme.transitions.create('opacity'),
}));


export default function Prueba() {
  return (
    <Grid  p={2} columns={15} spacing={0.5} container alignItems="stretch" sx={{ paddingLeft:"20%",paddingRight:"20%",display: 'flex', flexWrap: 'wrap', width: '100%'}}>
      {images.map((image) => (
        <Grid item  xs={7} sm={5} md={3}>
        <ImageButton
        focusRipple
        key={image.title}
        style={{
          width: "100%",
        }}
      >
        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="body"
            fontSize={"14px"}
            color="inherit"
            sx={{
              position: 'relative',
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {image.title}
          </Typography>
        </Image>
      </ImageButton>
        </Grid>
       
      ))}
    </Grid>
  );
}