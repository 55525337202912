import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import SocialButtons from './Redes';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary"style={{marginBottom:"10px"}}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <Link color="inherit" href="/">
        CedasDent
      </Link>

      {'.'}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0',
        alignContent:'center',
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          justifyContent:"center",
          alignItems:"center",
          textAlign:'center',
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">

          <Divider><SocialButtons/></Divider>

          <Copyright />   
        </Container>  
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              maxWidth={"md"}
              marginRight={"auto"}
              marginLeft={"auto"}
            >
            <Grid item xs={12} md={6}>
              <Typography variant="body" color="text.secondary" >
              Direccion
              </Typography>       
              <Typography variant="body2" color="text.secondary" >
              Pje. Vivar 1510, 1390000
              </Typography>
              <Typography variant="body2" color="text.secondary" >
              Calama, Antofagasta, Chile
              </Typography>
            </Grid>  
            <Grid item xs={12} md={6}>
              <Typography variant="body" color="text.secondary">
                Horario
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Lunes a viernes de 8:00 a 20:00
                </Typography>

                <Typography variant="body2" color="text.secondary">
                Sabado de 8:00 a 14:00
                </Typography>
            </Grid>
          </Grid>


      </Box>
    </Box>
  );
}
