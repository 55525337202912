import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const drawerWidth = 240;
const navItems = ['Inicio', 'Servicios', 'Nosotros','Citas','Contactos'];

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(25px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: 
        `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'light' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'light' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage:         `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'light' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));


function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      <img
      src='LogoPagina.webp'
      alt='CedasDent'
      style={{ width: '100px', height: 'auto', marginRight: 'auto' }}
    />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <NavLink
            key={item}
            to={`/${item.toLowerCase()}`}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "menuactive" : ""
            }
          >
            <Button sx={{ color: '#000',width:'100%',textAlign: "center" }}>
              {item}
            </Button>
          </NavLink>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
      <Toolbar>
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="start"
    onClick={handleDrawerToggle}
    sx={{ mr: 2, display: { sm: 'none' } }}
  >
    <MenuIcon />
  </IconButton>
  <Box sx={{ display: 'flex', alignItems: 'center',boxSizing:"inherit" }}>
    <img
      src='LogoPagina.webp'
      alt='CedasDent'
      style={{ width: '100px', height: "auto !important", zIndex:1 }}
    />
<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
  {navItems.map((item) => (
    <NavLink
      key={item}
      to={`/${item.toLowerCase()}`}
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "menuactive" : ""
      }
    >
      <Button
        sx={{
          color: '#fff',
          position: 'relative',
          '&:hover ul': {
            display: 'block'
          }
        }}
      >
        {item}
        {item === 'servicios' && (
          <ul
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              minWidth: '100%',
              background: '#fff',
              padding: '10px',
              display: 'none',
              zIndex: 1,
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)'
            }}
          >
            <li>Submenu item 1</li>
            <li>Submenu item 2</li>
            <li>Submenu item 3</li>
          </ul>
        )}
      </Button>
    </NavLink>
  ))}
</Box>
    
  </Box>
  <MaterialUISwitch onClick={()=> props.setDarkMode(!props.darkMode)}/>
</Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ paddingBottom:"14px"}}>
        <Toolbar />
      </Box>
    </Box>
  );
}





DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;