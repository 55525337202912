import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { FaTeethOpen,FaTooth,FaTeeth } from 'react-icons/fa';
import { useTheme } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const urltest=[
  "https://mui.com/static/images/cards/contemplative-reptile.jpg",
  "https://mui.com/static/images/cards/paella.jpg",
  "https://mui.com/static/images/cards/live-from-space.jpg"]

const GroupButtons = () => {
  const theme=useTheme();
  const colorservice="rgba(138, 149, 151, 1)";//rgba(46, 192, 44, 1)
  const colorinformative="rgba(133, 42, 190, 1)"
  const colorother="rgba(138, 149, 151, 1)"

  const buttons = [
    { name: 'Escribenos a WhatsApp', image:'chatwp.webp', href: 'https://wa.me/56984495193', icon: <WhatsAppIcon fontSize='medium' />,color: colorother },
    { name: 'Llamanos al +56984495193', image:'callcenter.webp', href: 'tel:+56984495193', icon: <PhoneIcon fontSize='medium'/> ,color: colorother },
    { name: 'Donde estamos y Horarios', image:"maps.webp", href: '/contactos', icon: <LocationOnIcon  fontSize='medium'/> ,color: colorother },
    { name: 'Siguemos en Instagram', image:'instagram.webp', href: 'https://instagram.com/cedsadentcalama?igshid=MzRlODBiNWFlZA==', icon: <InstagramIcon fontSize='medium'/> ,color: colorother },
    { name: 'Teleconsultas Gratuitas', image:"Teleconsulta.webp", href: 'https://wa.me/56939287460', icon:  <ContactPhoneIcon fontSize="medium" /> ,color:colorservice },
    { name: 'Ortodoncia', image:"ortodoncia.webp", href: '/ortodoncia', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Implantología', image:'implantesoseos.webp', href: '/implante', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Estética Dental', image:"Estetica.webp", href: '/estetica_dental', icon: <FaTeeth fontSize='24'/> ,color: colorservice },
    { name: 'Atencion para niños', image:'ninos1.webp', href: '/atencion_nino', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Urgencias Dentales 24H', image:'urgencia.webp', href: 'https://wa.me/56984495193', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Rehabilitación Oral', image:'rehabilitacion1.webp', href: '/rehabilitacion', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Cirugía Bucal', image:"Cirugia1.webp", href: '/cirugia', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Endodoncia', image:'endodoncia.webp', href: '/endodoncia', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Periodoncia', image:'peridoncia.webp', href: '/peridoncia', icon: <FaTooth fontSize='24'/> ,color: colorservice },
    { name: 'Trastornos tempomandibulares', image:'temporomandibular.webp', href: '/tempomandibular', icon: <FaTooth fontSize='24'/> ,color: colorservice },
  ];
  
  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    width: '100% !important',
    height: 80,
    [theme.breakpoints.down('100s0')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      transform:'scale(1.1)',
      '& .MuiImageBackdrop-root': {
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      }
    },
  }));
  
  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#4CBB17", //#702670  #235347
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  }));
  


  return (
    /*
    <Grid container columns={20}  alignItems="stretch" align = "center" justifyContent='center'>
      {buttons.map((button) => (
        <Grid item  xs={10} sm={6} md={5} lg={4}  key={button.name} >
          <Button size="medium" href={button.href} variant="contained" style={{backgroundColor:button.color}}
          sx={{mr:"10px", height:"95%",width:"95%", alignItems:"center",justifyContent:"space-evenly",direction:"column",minHeight:"4em"}}
          >
          {button.icon}
          <div style={{width:"75%"}}>
            {button.name}
          </div>
          </Button>
      </Grid>
      ))}
    </Grid>
  */

    <Grid  p={2} columns={60} spacing={0.5} container alignItems="stretch" padding="0" m="0"
    sx={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
    {buttons.map((image) => ( 
      <Grid item  xs={60} sm={20} md={15} lg={12}>
        <NavLink
          to={image.href}
        >
          <ImageButton
            focusRipple
            key={image.name}
            style={{
              width: "100%",
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${image.image})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root"  style={{ backgroundColor: `${image.color}` }}/>
            <Image>
              <Typography
                component="span"
                variant="body"
                fontSize={"14px"}
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                <div>{image.icon}</div>
                {image.name}
              </Typography>
            </Image>
          </ImageButton>
        </NavLink>
      </Grid>
     


    ))}
  </Grid>




  );
};

export default GroupButtons;

