import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function Ortopedia() {

  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'0'},paddingRight:{lg:'15%',md:'5%',sm:'0%'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
              Servicios de especialidades
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3}   justifyContent="center"   alignItems="center">
            <img src={"endodoncia.webp"} style={{maxWidth:"100%",borderRadius:"10px"}} alt="" />
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
              Ortopedia clínica            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La ortopedia dental es una rama de la odontología que se enfoca en el diagnóstico, prevención y tratamiento de irregularidades óseas y dentales en niños y adolescentes. A diferencia de la ortodoncia, que se ocupa principalmente de corregir la alineación de los dientes, la ortopedia dental se centra en la corrección de problemas relacionados con el crecimiento y desarrollo de los maxilares y las estructuras faciales.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            El objetivo de la ortopedia dental es guiar y corregir el desarrollo óseo y dental para lograr una oclusión (mordida) adecuada y una armonía facial. Se ocupa de tratar problemas como maloclusiones esqueléticas, discrepancias en el tamaño y posición de los maxilares, problemas de crecimiento mandibular, entre otros.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            En el contexto de la ortopedia dental, se utilizan aparatos y dispositivos ortopédicos específicos para influir en el crecimiento de los maxilares y corregir las maloclusiones. Estos dispositivos pueden ser fijos o removibles, y suelen aplicar fuerzas controladas sobre los maxilares para lograr cambios en su posición y forma.
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default Ortopedia;