import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
const urltest=[
  "https://mui.com/static/images/cards/contemplative-reptile.jpg",
  "https://mui.com/static/images/cards/paella.jpg",
  "https://mui.com/static/images/cards/live-from-space.jpg"]
const images = [
  {	
    url: "Cirugia1.webp",	
    title: 'Cirugía oral',	link:"/cirugia"
  },	
  {	
    url:  'rehabilitacion1.webp',	
    title: 'Rehabilitación Oral',	link:"/rehabilitacion"
  },  	
  {	
    url: "Estetica.webp",	
    title: 'Odontológica estética',	link:"/estetica_dental"
  },  	
  {	
    url: "operatoria1.webp",	
    title: 'Operatoria',	link:"/operatoria"
  },  	
  {	
    url: 'peridoncia.webp',	
    title: 'Periodoncia',	link:"/peridoncia"
  },	
  {	
    url:'ninos1.webp',	
    title: 'Atención integral para niños',	link:"/atencion_nino"
  },	

  ];	
const espcial=[	
  {	
    url: "ortodoncia.webp",	
    title: 'Ortodoncia',	link:"/ortodoncia"
  },  	
  {	
    url: "endodoncia.webp",	
    title: 'Ortopedia clínica',	link:"/ortopedia"
  },	
  {	
    url:"implantesoseos.webp",	
    title: 'Implantes óseos',	link:"/implante"
  },	
  {	
    url: 'endodoncia.webp',	
    title: 'Endodoncias',	link:"/endodoncia"
  },	
  {	
    url: 'temporomandibular.webp',	
    title: 'Trastornos tempomandibulares',	link:"/tempomandibular"
  },	
]	
      


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height:"10vw",
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 80,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export function ButtonBases() {
  return (
    <Grid alignItems="stretch" sx={{ display: 'flex', flexWrap: 'wrap', width: '100%'}}>
      {images.map((image) => (
        <Grid item  xs={6} sm={6} md={4}>
        <NavLink to={image.link}>

        
          <ImageButton
          focusRipple
          key={image.title}
          style={{
            width: '100%',
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
        </NavLink>
        </Grid>
       
      ))}
    </Grid>
  );
}

export function EspecialButton() {
  return (
    <Grid alignItems="stretch" sx={{ display: 'flex', flexWrap: 'wrap', width: '100%'}}>
      {espcial.map((image) => (
        <Grid item  xs={6} sm={6} md={4}>
          <NavLink to={image.link}>
          <ImageButton
        focusRipple
        key={image.title}
        style={{
          width: '100%',
        }}
      >
        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: 'relative',
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {image.title}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        </Image>
      </ImageButton>
          </NavLink>
        
        </Grid>
       
      ))}
    </Grid>
  );
}