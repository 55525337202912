import React from 'react';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon  from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const SocialButtons = () => {
  return (
    <div>
      <IconButton href = 'https://www.facebook.com/ClinicaDentalCedsaDentCalama/'>
        <FacebookIcon color="default" fontSize="medium" />
      </IconButton>
      <IconButton href = 'tel:+56984495193'>
        <PhoneIcon color="default" fontSize="medium" />
      </IconButton>
      <IconButton href = 'https://wa.me/56984495193'>
        <WhatsAppIcon color="default" fontSize="medium" />
      </IconButton>
      <IconButton href = 'https://instagram.com/cedsadentcalama?igshid=MzRlODBiNWFlZA=='>
        <InstagramIcon color="default" fontSize="medium" />
      </IconButton>
      <IconButton href = 'https://goo.gl/maps/dABwa8rAcqKgGbuY9'>
        <LocationOnIcon color="default" fontSize="medium" />
      </IconButton>
      
    </div>
  );
};

export default SocialButtons;
