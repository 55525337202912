import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function AtencionNinos() {

    const submenus=[
        {id:1,text:"Sellantes"},
        {id:2,text:"Limpieza"},
        {id:3,text:"Tapaduras"},
        {id:4,text:"Exodoncia"},
        {id:5,text:"Pulpectomía"},    
    
    ]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Servicios Generales
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" paddingTop={"16px"}>
            <Grid item xs={6} md={12}justifyContent="center" display={"flex"}>
                <img src={"ninos1.webp"} style={{maxWidth:"100%",widht:"100%",maxHeight:"250px",borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
            Atención integral para niños            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La atención odontológica para niños, también conocida como odontopediatría, se refiere a la atención dental especializada para los niños desde la infancia hasta la adolescencia. Es una rama de la odontología que se centra en el cuidado de la salud oral de los niños, brindando servicios de prevención, diagnóstico y tratamiento adaptado a sus necesidades específicas.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La atención odontológica para niños es de suma importancia, ya que los dientes de leche son fundamentales para el correcto desarrollo de los dientes permanentes y la salud oral a lo largo de la vida. Algunos aspectos destacados de la atención odontológica para niños incluyen:
            </Typography>
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Sellantes            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Un sellante dental es un recubrimiento protector aplicado en las superficies de masticación de los dientes molares y premolares. Está compuesto por un material resinoso o de vidrio ionómero y se utiliza para prevenir la aparición de caries en estas áreas propensas a la acumulación de placa y bacterias.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Los sellantes dentales son especialmente beneficiosos en niños y adolescentes, ya que sus dientes recién erupcionados son más susceptibles a las caries. Sin embargo, también se pueden utilizar en adultos con dientes sanos que presentan fosas y fisuras profundas y son difíciles de limpiar adecuadamente.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Es importante tener en cuenta que los sellantes dentales no son permanentes y pueden desgastarse con el tiempo. Es recomendable realizar un control regular con el dentista para verificar su estado y reemplazarlos si es necesario.
                    </Typography>
                </Paper>
            }
            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Limpieza            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una limpieza dental en niños, también conocida como profilaxis dental, es un procedimiento realizado para limpiar y mantener la salud bucal de los niños. La limpieza dental en niños tiene como objetivo principal prevenir la caries dental y promover una buena higiene oral desde una edad temprana.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Además de las limpiezas regulares, es importante enseñar a los niños hábitos adecuados de higiene oral, incluido el cepillado dos veces al día con pasta dental con flúor y el uso de hilo dental. También se recomienda una dieta equilibrada y limitar el consumo de alimentos y bebidas azucaradas.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Es importante destacar que las limpiezas dentales en niños suelen ser experiencias amigables y no dolorosas. Los profesionales de la salud dental están capacitados para abordar las necesidades y preocupaciones específicas de los niños, creando un ambiente relajado y positivo para ellos.
                    </Typography>
                </Paper>
            }
            {selectedOption === 3 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Tapaduras            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una tapadura dental en niños, también conocida como empaste u obturación dental, es un procedimiento en el que la dentista rellena y sella una cavidad o una caries en un diente utilizando un material restaurador. Esta técnica se utiliza para restaurar la forma, la función y la integridad del diente afectado por la caries y prevenir el avance de la enfermedad dental.
                     </Typography>
                    
                </Paper>
            }
            {selectedOption === 4 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Exodoncia           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La exodoncia en niños es el procedimiento de extracción de un diente de leche o diente permanente en un niño. La exodoncia puede ser necesaria por diversas razones, como caries extensas, dientes dañados o fracturados, infecciones dentales, dientes supernumerarios (dientes adicionales) o para facilitar la erupción adecuada de los dientes permanentes.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Es fundamental seguir las recomendaciones del dentista para los cuidados posteriores y programar visitas de seguimiento para asegurarse de que la extracción haya sido exitosa y el niño se recupere adecuadamente.
                    </Typography>
                </Paper>
            }
            {selectedOption === 5 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Pulpectomía           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una pulpectomía en niños es un procedimiento dental que implica la remoción del tejido pulpar inflamado o infectado en un diente de leche. La pulpa dental es el tejido blando en el interior del diente que contiene los vasos sanguíneos, los nervios y los tejidos conectivos. Una pulpectomía se realiza cuando la pulpa está dañada debido a una caries profunda, una lesión o una infección, y el objetivo es aliviar el dolor, tratar la infección y preservar el diente de leche hasta que se caiga naturalmente.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Es importante mencionar que la pulpectomía en niños se realiza para tratar y preservar los dientes de leche, ya que estos desempeñan un papel importante en la masticación, el habla y el desarrollo adecuado de los dientes permanentes. Los dientes de leche también mantienen el espacio necesario para que los dientes permanentes erupcionen correctamente en el futuro.
                    </Typography>
                </Paper>
            }
        </Drawer>
    </Grid>
  );
}

export default AtencionNinos;