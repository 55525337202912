import React, { Fragment } from 'react';
import Navbar2 from "./Navbar2";
import Footer from './Footer';
import WhatsappButton from './WPButton';
import DefaultNavbar from "./Navbar";

const Layout = (props) => {
  return (
    <Fragment>
      <div style={{minHeight:"100vh"}}>
        <Navbar2 darkMode={props.darkMode}  setDarkMode={props.setDarkMode}/>
        {props.children}
        <WhatsappButton/>
        <Footer/>
      </div>

    </Fragment>
  );
};

export default Layout;