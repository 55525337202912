import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function Ortodoncia() {

  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'0'},paddingRight:{lg:'15%',md:'5%',sm:'0%'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
              Servicios de especialidades
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3}   justifyContent="center" sx={{paddingTop:{xs:"10px",md:"50px"}}} alignItems="start">
            <img src={"ortodoncia.webp"} style={{maxWidth:"100%",borderRadius:"10px"}} alt="" />
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
              Ortodoncia            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La ortodoncia es una especialidad de la odontología que se centra en el diagnóstico, prevención y tratamiento de las irregularidades dentales y faciales, principalmente relacionadas con el mal posicionamiento de los dientes y la mandíbula. El objetivo de la ortodoncia es lograr una correcta alineación dental y una mordida adecuada, mejorando tanto la estética como la función de la boca.            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            El tratamiento de ortodoncia puede ser necesario cuando hay problemas de maloclusión dental, que incluyen:
            </Typography>
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
              <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                <ListItemText 
                  primary={
                    <Typography variant="body" fontFamily="Montserrat">
                      Apiñamiento dental: Los dientes están superpuestos o amontonados debido a la falta de espacio en la boca.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                <ListItemText 
                  primary={
                    <Typography variant="body" fontFamily="Montserrat">
                      Separación dental: Hay espacios excesivos entre los dientes debido a dientes faltantes o anomalías del tamaño de los dientes.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                <ListItemText
                  primary={
                    <Typography variant="body" fontFamily="Montserrat">
                      Mordida cruzada: Los dientes superiores muerden por dentro de los inferiores cuando la mandíbula está cerrada.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                <ListItemText
                  primary={
                    <Typography variant="body" fontFamily="Montserrat">
                      Sobremordida: Los dientes superiores cubren en exceso los dientes inferiores cuando la mandíbula está cerrada.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                <ListItemText
                  primary={
                    <Typography variant="body" fontFamily="Montserrat">
                      Mordida abierta: Existe un espacio vertical entre los dientes superiores e inferiores cuando la mandíbula está cerrada.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                <ListItemText
                  primary={
                    <Typography variant="body" fontFamily="Montserrat">
                      Prognatismo o retrognatismo mandibular: La mandíbula inferior está más adelante o más atrás de lo que se considera normal.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La ortodoncia no solo mejora la apariencia estética de la sonrisa, sino que también puede tener beneficios funcionales y de salud a largo plazo, como una masticación adecuada, una mejor pronunciación del habla y una menor probabilidad de problemas dentales y de la articulación temporomandibular en el futuro.
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default Ortodoncia;