import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function Tempomandibular() {

    const submenus=[
        {id:1,text:"Plano de alivio oclusal"},
        {id:2,text:"Plano miorrelajante"},
        {id:3,text:"Tratamiento farmacológico"}
    ]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"#027a89"}>
                Servicios de especialidades
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" alignItems="center" paddingTop={"16px"}>
            <Grid item xs={6} md={12}>
                <img src={"temporomandibular.webp"} style={{maxWidth:"100%",widht:"100%",borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"#027a89"}>
            Trastornos tempomandibulares            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Los trastornos temporomandibulares (TTM) son afecciones que afectan la articulación de la mandíbula, causando síntomas como dolor, dificultad para abrir la boca y chasquidos. El diagnóstico se basa en los síntomas y un examen clínico, y el tratamiento puede incluir terapia conservadora, medicamentos y, en casos graves, cirugía.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Los trastornos temporomandibulares son afecciones que afectan la articulación de la mandíbula, causando dolor y problemas en la apertura y cierre de la boca.
            </Typography>
            
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"#027a89"} sx={{paddingBottom:"3%"}}>
                    Plano de alivio oclusal            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Un plano de alivio oclusal es un dispositivo utilizado en odontología para modificar la relación oclusal de los dientes y aliviar la presión o el estrés sobre ciertas áreas de la dentición. Se utiliza principalmente en el tratamiento de trastornos temporomandibulares (TMD) y bruxismo, una condición en la cual las personas aprietan o rechinan los dientes de manera involuntaria.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El plano de alivio oclusal es una placa delgada, generalmente hecha de acrílico o resina, que se coloca sobre los dientes superiores o inferiores. Está diseñado para ajustar la forma en que los dientes superiores e inferiores encajan entre sí (oclusión), de modo que se redistribuya la fuerza masticatoria y se reduzca la tensión en los músculos y las articulaciones temporomandibulares.
                    </Typography>
                                                        
                </Paper>
            }

            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"#027a89"} sx={{paddingBottom:"3%"}}>
                    Plano miorrelajante            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Un plano miorrelajante es un dispositivo utilizado en odontología para relajar los músculos de la mandíbula y aliviar la tensión o el estrés en la articulación temporomandibular (ATM). También se conoce como férula de descarga oclusal o férula oclusal de relajación.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El objetivo principal de un plano miorelajante es reducir la tension muscular y promover una posicion de descanso mas relajada de la mandibula. Esto puede ayudar a aliviar los síntomas asociados con el bruxismo y los trastornos temporomandibulares, como dolor en la mandíbula, dolor de cabeza, dolor de oído y limitación en la apertura de la boca.
                    </Typography>
                </Paper>
            }
            {selectedOption === 3 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"#027a89"} sx={{paddingBottom:"3%"}}>
                    Tratamiento farmacológico            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El tratamiento farmacológico para los trastornos temporomandibulares (TMD) se enfoca en aliviar los síntomas asociados, como el dolor, la inflamación y la tensión muscular. Sin embargo, es importante tener en cuenta que el tratamiento farmacológico generalmente se combina con otras terapias, como cambios en el estilo de vida, terapia física y/o dispositivos de férulas, para lograr resultados óptimos. Algunos medicamentos comúnmente utilizados para el tratamiento de los TMD incluyen:
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Analgésicos: Los analgésicos de venta libre, como el paracetamol o el ibuprofeno, pueden ser útiles para aliviar el dolor leve a moderado asociado con los TMD. En casos de dolor más intenso, pueden recetarse analgésicos más potentes, como los opioides, pero se debe tener precaución debido a sus efectos secundarios y el riesgo de adicción.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Relajantes musculares: Los relajantes musculares pueden ayudar a reducir la tension
                    </Typography>
                </Paper>
            }
            
        </Drawer>
    </Grid>
  );
}

export default Tempomandibular;