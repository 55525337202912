import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function Rehabilitacion() {

    const submenus=[
        {id:1,text:"Prótesis fijas"},
        {id:2,text:"Prótesis parcial removible"},
        {id:3,text:"Prótesis totales"},
        {id:4,text:"Prótesis sobre implantes"},
        {id:5,text:"Prótesis flexibles"},
        {id:6,text:"Prótesis con base metálica"}

    ]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Servicios Generales
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" alignItems="center" paddingTop={"16px"}>
            <Grid item xs={6} md={12}>
                <img src={"rehabilitacion1.webp"} style={{maxWidth:"100%",widht:"100%",borderRadius:"10px"}} alt="" />
            </Grid>
            <Grid item xs={6} md={12}>
                <img src={"rehabilitacion2.webp"} style={{maxWidth:"100%",widht:"100%",height: 'auto',borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Rehabilitación Oral            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La rehabilitación oral es una rama de la odontología que se enfoca en restaurar y mejorar la función y apariencia de los dientes, encías y estructuras relacionadas. Es un proceso integral que combina diferentes técnicas y procedimientos para tratar problemas dentales complejos.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La rehabilitación oral aborda situaciones en las que hay una pérdida significativa de estructuras dentales, como dientes ausentes, dientes dañados o desgastados, problemas en las encías, mordida incorrecta o maloclusión, y otras condiciones que dependen de la función y estética de la boca.
            </Typography>
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Prótesis fijas            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una prótesis fija es un tipo de restauración dental que se utiliza para reemplazar uno o varios dientes perdidos. También se conoce como puente dental o corona puente.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una prótesis fija está compuesta por uno o varios dientes artificiales (llamados pónticos) que están unidos a las piezas dentales adyacentes utilizando coronas dentales. Estas coronas, también conocidas como pilares, se cementan o se adhieren a los dientes naturales que están a ambos lados del espacio donde falta el diente.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Existen diferentes tipos de prótesis fija, como:
                    </Typography> 
                    <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                            <ListItemText 
                            primary={
                                <Typography variant="body" fontFamily="Montserrat">
                                Puente tradicional: Consiste en uno o varios pónticos que se unen a coronas dentales colocadas sobre los dientes naturales adyacentes.
                                </Typography>
                            }
                            />
                        </ListItem>
                        <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                            <ListItemText 
                            primary={
                                <Typography variant="body" fontFamily="Montserrat">
                                Corona dental: Es una prótesis que cubre completamente un diente dañado o debilitado. Se utiliza cuando el diente natural tiene suficiente estructura sana para soportar la corona. La corona dental proporciona fuerza, protección y mejora la apariencia estética del diente.
                                </Typography>
                            }
                            />
                        </ListItem>
                    </List>                   
                </Paper>
            }
            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Prótesis parcial removible           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La prótesis parcial removible es un tipo de prótesis dental utilizada para reemplazar uno o varios dientes faltantes en una boca parcialmente edéntula (con algunos dientes naturales aún presentes). También se le conoce comúnmente como "placa parcial" o "puente parcial removible".
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La prótesis parcial removible consta de una base de metal o acrílico que se apoya en los tejidos blandos de la boca y en los dientes naturales restantes. Esta base tiene los dientes artificiales que reemplazan a los ausentes. También incluye ganchos o dispositivos de retención que se enganchan en los dientes naturales adictivos para mantener la prótesis en su lugar.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Existen diferentes tipos de prótesis parciales removibles, como las prótesis acrílicas y las prótesis esqueléticas metálicas. Las prótesis acrílicas son más económicas, pero también menos duraderas, mientras que las prótesis esqueléticas metálicas son más estables y duraderas, pero también más costosas.
                    </Typography>
                </Paper>
            }
            {selectedOption === 3 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Prótesis totales            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una prótesis total, también conocida como dentadura postiza o prótesis dental completa, es un dispositivo utilizado para reemplazar todos los dientes en una boca edéntula (sin dientes naturales). Proporciona una solución funcional y estética para aquellas personas que han perdido todos sus dientes.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La prótesis total consta de una base de acrílico que se ajusta a la forma del maxilar superior o la mandíbula inferior. Esta base sostiene los dientes artificiales que reemplazan a los dientes naturales perdidos. La prótesis total se apoya en los tejidos blandos de la boca, como las encías, y puede estabilizarse en su lugar mediante succión y el uso de adhesivos dentales.
                    </Typography>
                </Paper>
            }
            {selectedOption === 4 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Prótesis sobre implantes           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una prótesis sobre implantes, también conocida como prótesis dental implantosoportada, es un tipo de prótesis dental utilizada para reemplazar uno o varios dientes perdidos. Esta prótesis se apoya y se sujeta a implantes dentales colocados en el hueso maxilar o mandibular.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Los implantes dentales son pequeños tornillos de titanio que se insertan en el hueso de la mandíbula o el maxilar mediante una cirugía. Estos implantes actúan como raíces artificiales y proporcionan una base sólida y estable para soportar la prótesis dental.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La prótesis sobre implantes consta de dos componentes principales: los implantes y la estructura protésica. Los implantes dentales se colocan estratégicamente en el hueso y, una vez integrados de forma segura con el tejido circundante (proceso llamado osteointegración), se une la estructura protésica.
                    </Typography>
                </Paper>
            }
            {selectedOption === 5 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Prótesis flexibles           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una prótesis dental flexible es una opción de tratamiento para reemplazar los dientes perdidos. También se conoce como una prótesis parcial removible flexible. Está diseñada para ser liviana, cómoda y estéticamente agradable.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    A diferencia de las prótesis parciales removibles convencionales, que están hechas principalmente de metal y acrílico rígido, las prótesis flexibles están fabricadas con materiales flexibles y resistentes, como nylon o polímeros flexibles. Estos materiales son duraderos, resistentes a la fractura y se adaptan bien a los contornos de la boca.
                    </Typography>
                </Paper>
            }
                        {selectedOption === 6 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Prótesis con base metálica           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una prótesis metálica es un tipo de prótesis dental o dispositivo protésico utilizado para reemplazar uno o varios dientes perdidos.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Posee una estructura metálica que forma parte de la base de la prótesis que se coloca en la boca. Estas estructuras proporcionan estabilidad y resistencia, permitiendo que la prótesis se mantenga en su lugar mientras se habla o se come. Además, las prótesis metálicas removibles también pueden tener ganchos metálicos que se enganchan a los dientes naturales adyacentes para mayor sujeción.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las prótesis metálicas tienen varias ventajas. Son duraderas y resistentes a la fractura, lo que las hace ideales para soportar fuerzas masticatorias. También son menos propensas a acumular placa bacteriana en comparación con otros materiales protésicos, lo que contribuye a una mejor higiene oral.
                    </Typography>
                </Paper>
            }
        </Drawer>
    </Grid>
  );
}

export default Rehabilitacion;