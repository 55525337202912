import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function Cirugia() {

    const submenus=[
        {id:1,text:"Exodoncias de terceros molares"},
        {id:2,text:"Exodoncias simples"},
        {id:3,text:"Exodoncias complejas"},
        {id:4,text:"Frenectomía"}]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Servicios Generales
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" alignItems="center" paddingTop={"16px"}>
            <Grid item xs={6} md={12}justifyContent="center" alignItems="center"display={"flex"}>
                <img src={"Cirugia2.webp"} style={{maxWidth:"100%",widht:"100%",maxHeight:"150px",borderRadius:"10px"}} alt="" />
            </Grid>
            <Grid item xs={6} md={12}justifyContent="center" alignItems="center" display={"flex"}>
                <img src={"Cirugia1.webp"} style={{maxWidth:"100%",widht:"100%",maxHeight:"150px",height: 'auto',borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Cirugía oral            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La cirugía oral es una especialidad de la odontología que se encarga del diagnóstico y tratamiento de enfermedades, lesiones y defectos relacionados con la oral, los maxilares y los tejidos faciales adyacentes. 
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Esta rama de la odontología abarca una amplia variedad de procedimientos quirúrgicos que se realizan en la cavidad oral.
            </Typography>
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                        Exodoncias de terceros molares            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La exodoncia de terceros molares se refiere a la extracción quirúrgica de los terceros molares, conocidos como "muelas del juicio". Los terceros molares son los últimos dientes en desarrollarse en la boca, ya menudo pueden causar problemas debido a su falta de espacio en el arco dental.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La exodoncia de terceros molares suele ser necesaria cuando estos dientes están impactados o no pueden erupcionar adecuadamente. Los terceros molares impactados son aquellos que no pueden emerger completamente debido a la falta de espacio en la boca por su posición incorrecta. Esto puede provocar dolor, infecciones, daño a los dientes adyacentes e incluso problemas de alineación dental.
                    </Typography>
                </Paper>
            }
            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Exodoncias simples            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una exodoncia simple es el procedimiento quirúrgico en el cual se extrae un diente de la boca. Se considera "simple" cuando se realiza la extracción de un diente que está completamente erupcionado y cuya remoción no requiere técnicas o instrumentos especiales adicionales.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Antes de realizar la exodoncia simple, se realiza una evaluación cuidadosa del diente y los tejidos circundantes para determinar la necesidad de la extracción y para planificar el procedimiento de manera adecuada. El profesional dental también puede solicitar radiografías dentales para obtener una mejor visualización de la estructura dental y asegurarse de que no haya complicaciones ocultas.
                    </Typography>
                </Paper>
            }
            {selectedOption === 3 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Exodoncias complejas            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una exodoncia compleja se refiere a un procedimiento quirúrgico más complicado y difícil para extraer un diente. Por lo general, implica la extracción de un diente que puede presentar diversas complicaciones, como la presencia de raíces curvas, fracturas dentales, dientes impactados o retenidos, o la necesidad de realizar incisiones y suturas adicionales.
                     </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las exodoncias complejas generalmente se realizan cuando la extracción del diente no puede llevarse a cabo de manera rutinaria, debido a factores anatómicos o patológicos que dificultan el procedimiento. En estos casos, se puede requerir una planificación cuidadosa y el uso de técnicas quirúrgicas especiales para lograr una extracción exitosa
                     </Typography>
                </Paper>
            }
            {selectedOption === 4 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Frenectomía           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una frenectomía es un procedimiento quirúrgico en el que se elimina o modifica el frenillo, una pequeña banda de tejido conectivo que se encuentra en diferentes partes del cuerpo humano. El frenillo es una estructura que puede limitar el movimiento o la función de ciertas áreas, especialmente cuando es demasiado corto, grueso o tenso.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    En el contexto dental y oral, la frenectomía más común es la frenectomía lingual o frenulotomía lingual, que consiste en la eliminación o modificación del frenillo lingual. El frenillo lingual es la banda de tejido que conecta la lengua con el suelo de la boca. Si el frenillo lingual es muy corto o tenso, puede causar limitaciones en el movimiento de la lengua, lo que puede afectar la capacidad de hablar, comer o incluso respirar adecuadamente. En estos casos, se puede recomendar una frenectomía lingual para liberar la lengua y permitir un movimiento más completo y funcional.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    La frenectomía también se puede realizar en otras áreas del cuerpo, como en la región labial (frenectomía labial) o en el área genital (frenectomía prepucial en los hombres). Estos procedimientos también implican la eliminación o modificación del frenillo correspondiente para mejorar
                    </Typography>
                </Paper>
            }
        </Drawer>
    </Grid>
  );
}

export default Cirugia;