import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { NavLink } from 'react-router-dom';
import { useRef, useEffect,useState } from "react";
import "@fontsource/montserrat";
import { Popper,Fade,Paper,MenuList } from '@mui/material';



const pages = ['Inicio','Agenda tu hora', 'Servicios', 'Nosotros','Contactos'];
const servicios=[
  {	name:"Cirugía oral",	url:"cirugia"}	,
  {	name:"Rehabilitación Oral",	url:"rehabilitacion"}	,
  {	name:"Odontológica estética",	url:"estetica_dental"}	,
  {	name:"Operatoria",	url:"operatoria"}	,
  {	name:"Periodoncia",	url:"peridoncia"}	,
  {	name:"Atención integral para niños",	url:"atencion_nino"}	,
  {	name:"Ortodoncia",	url:"ortodoncia"}	,
  {	name:"Ortopedia clínica",	url:"ortopedia"}	,
  {	name:"Implantes óseos",	url:"implante"}	,
  {	name:"Endodoncias",	url:"endodoncia"}	,
  {	name:"Trastornos tempomandibulares",	url:"tempomandibular"}	
]

function ResponsiveAppBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [referenceEl, setReferenceEl] = useState(null);
  const handleOpenPopper = (event) => {
    setOpen(true);
  };
  const handleClosePopper = (event) => {
    setOpen(false);
  };


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const appBarColor = props.darkMode ?  'rgba(86, 86, 86, 1)':"#00aec3" ;//'#164620'#343E40:"#007500" '#4E1773':"#5F1B8C" 


  return (
    <AppBar position="static" style={{background: appBarColor}}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters style={{justifyContent:"space-between"}}>
        
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: {xs:"0", md:"1"} }} >
              <NavLink to="/" sx={{ display: { xs: 'none', md: 'flex' }}}>
                <img
                src='LogoW.webp'
                alt='CedasDent'
                style={{ width: '80px', height: "auto !important", zIndex:1 }}
                />
              </NavLink>
            </Box>

        <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              paddingLeft:"10px",
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CEDSADENT
          </Typography>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <NavLink
                  key={page}
                  to={`/${page.toLowerCase()}`}
                  style={{ textDecoration: 'none' }}
                  sx={{textDecoration:"none"}}
                >
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography  textAlign="center" color="text.primary" fontFamily={"Montserrat"}>{page}</Typography>
                </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>
          
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, alignContent:"center"}} >
            <NavLink to="/">
                <img
                src='LogoW.webp'
                alt='CedasDent'
                style={{ width: '100px', height: "auto !important", zIndex:1 }}
                />
            </NavLink>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs:"none",sm: 'flex', md: 'none' },
              flexGrow: 1,
              fontSize:{xs:"0%",sm:'100%'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >CEDSADENT
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },justifyContent: 'flex-end' }}>
          <Box sx={{justifyContent:"space-evenly",width:{md:"100%", lg:"60%"},display:"flex",alignItems:"flex-end"}}>
        
            {pages.map((page) => (
              <NavLink
              key={page}
              to={`/${page.toLowerCase()}`}
              style={{color: 'inherit', textDecoration: 'inherit'}}
              onClick={handleCloseNavMenu}
              onMouseEnter={page === "Servicios" ?handleOpenPopper:null}
              onMouseLeave={page === "Servicios" ?handleClosePopper:null}
              
              > 
              {page === "Servicios" ? (

                <Button
                  key={page}
                  sx={{ my: 2, color:  props.darkMode ?"white":"white", display: 'block',fontFamily:"Montserrat"}}
                  ref={page === "Servicios"?setReferenceEl:null}
                >
                  {page}
                </Button>
              ):(
                
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: props.darkMode ?"white":"white", display: 'block',fontFamily:"Montserrat"}}
                >
                  {page}
                </Button>
                
              )}
              </NavLink>
            ))}
          </Box>
            <Popper 
            style={{zIndex:"10"}}
            open={open} anchorEl={referenceEl} 
            onMouseEnter={handleOpenPopper} 
            onMouseLeave={handleClosePopper}
            placement={'bottom-start'} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={150}>
                  <Paper >
                    <MenuList>
                    {servicios.map((servicio) => (
                      <NavLink
                        key={servicio.name}
                        to={servicio.url}
                        style={{ textDecoration: 'none' }}
                        sx={{textDecoration:"none"}}
                      >
                      <MenuItem onClick={handleClosePopper}>
                        <Typography  textAlign="center" color="text.primary" fontFamily={"Montserrat"}>{servicio.name}</Typography>
                      </MenuItem>
                      </NavLink>
                    ))}
                    </MenuList>
                  </Paper>
                </Fade>
              )}
            </Popper>


          </Box>
        
            <MaterialUISwitch onClick={()=> props.setDarkMode(!props.darkMode)}/>
          {/*<Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
              </Box>*/}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const sun =
`url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
'#000',
)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`;
const moon=          `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  '#fff',
)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`;

const MaterialUISwitch = styled(Switch)(({ theme }) => ({

    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 0,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(25px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: theme.palette.mode === 'light' ?sun:moon,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'light' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? 'black':"#white",
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: theme.palette.mode === 'light' ?sun:moon  ,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'light' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
  


export default ResponsiveAppBar;