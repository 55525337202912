import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function Implantes() {

  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'0'},paddingRight:{lg:'15%',md:'5%',sm:'0%'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
              Servicios de especialidades
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3}   justifyContent="center" sx={{paddingTop:{xs:"10px",md:"50px"}}}>
            <img src={"implantesoseos.webp"} style={{maxWidth:"100%",borderRadius:"10px",height:"300px"}} alt="" />
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
            Implantes óseos integrado            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Un implante óseo integrado es un dispositivo médico utilizado en odontología y cirugía maxilofacial para reemplazar los dientes perdidos o restaurar la función de la mandíbula. Consiste en un tornillo de titanio que se inserta quirúrgicamente en el hueso maxilar o mandibular, y actúa como una raíz artificial para sostener una prótesis dental, como una corona, puente o dentadura.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Una vez que el implante ha cicatrizado adecuadamente, se coloca una prótesis dental personalizada sobre el implante. Esta prótesis se adapta a la boca del paciente y se une de forma segura al implante, restaurando la función y la estética dental.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Los implantes óseos integrados ofrecen varias ventajas en comparación con otras opciones de restauración dental, como dentaduras postizas o puentes dentales convencionales. Proporcionan una base sólida y estable para las prótesis, lo que permite una mejor función al masticar y hablar. Además, los implantes óseos integrados estimulan el hueso circundante, lo que ayuda a prevenir la pérdida ósea y el deterioro facial que puede ocurrir cuando falta un diente.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Es importante tener en cuenta que la colocación de implantes óseos integrados es un procedimiento quirúrgico y requiere una evaluación y planificación cuidadosas, así como la supervisión de un profesional médico calificado.
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default Implantes;