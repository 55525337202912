import Grid from '@mui/material/Grid';
import ButtonBases from './ListaServicios';
import React, { useRef, useEffect } from "react";
import Typography from'@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardMedia from "@mui/material/CardMedia";
import Box from '@mui/material/Box';
import { Carousel, CarouselNosotros } from './Carousel';
import "@fontsource/montserrat";

function Nosotros() {
  return (
    <Grid container sx={{p:"10px",paddingLeft:{lg:'20%',md:'10%',sm:'5%'},paddingRight:{lg:'20%',md:'10%',sm:'5%'}}}>
      <Grid container  xs={12} p="20px" >
        <Grid item xs={12} sx={{ textAlign:{md:'justify',sm:'left'}}}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8}>
              <Grid container direction="column" alignItems="left" justifyContent="center">
              <Typography variant="h4" fontFamily="Montserrat">
                ¿Quienes somos?
              </Typography>

              <Typography variant="body" fontSize={16} textAlign={"justify"} fontFamily="Montserrat" style={{paddingBottom:"3px"}}>
                Cedsa Dent Villegas EIRL – Clínica odontológica Especializada, es una empresa integral, con un Staff de Odontólogos especialistas y personal administrativo de primer nivel, comprometidos con el perfeccionamiento permanente y en búsqueda de una salud oral en la población. 
              </Typography>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
              <Grid container direction="column" alignItems="center" justifyContent="center" style={{height:"100%"}}>
                <img src={"/consultorio1.webp"} style={{maxWidth:"90%",maxHeight:"40vh",borderRadius:"5%"}} alt="" />
              </Grid>
          </Grid>
        </Grid>
          <Divider  style={{marginTop:"10px",marginBottom:"10px"}}/>
        </Grid>

        <Grid item xs={12} sx={{ textAlign:{md:'justify',sm:'left'}}}>
        <Grid container spacing={3} sx={{ flexDirection: {  md: "row",xs:"column-reverse",sm: "column-reverse"} }}>
          <Grid item xs={12} sm={12} md={4} alignItems="center" justifyContent="center">
              <Grid container direction="column" alignItems="center" justifyContent="center" style={{height:"100%"}}>
                <img src={"/consultorio2.webp"} style={{maxWidth:"100%", maxHeight:"40vh",borderRadius:"5%"}} alt="" />
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
              <Grid container direction="column" alignItems="left" justifyContent="center">
              <Typography variant="h4" fontFamily="Montserrat">
              Nuestra Mision
              </Typography>

              <Typography variant="body" fontSize={16} textAlign={"justify"} fontFamily="Montserrat" style={{paddingBottom:"3px"}}>
                Es nuestra misión brindar atención odontológica de calidad y de primer nivel a la población que confíe en nosotros, constituyéndonos en una alternativa de solución a sus necesidades de salud oral, a través de un servicio óptimo, especializado y de calidad. Así también incentivar el desarrollo permanente de nuestro staff a través de actividades de capacitación, investigación y concientización social que motive el desenvolvimiento eficaz y eficiente, para el logro de los objetivos.
              </Typography>
              </Grid>
          </Grid>
        </Grid>
          <Divider  style={{marginTop:"10px",marginBottom:"10px"}}/>
        </Grid>

        <Grid item xs={12} sx={{ textAlign:{md:'justify',sm:'left'}}}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={12} md={8}>
                <Grid container direction="column" alignItems="left" justifyContent="center">
                <Typography variant="h4" fontFamily="Montserrat">
                Nuestra Vision
                </Typography>

                <Typography variant="body" fontSize={16} textAlign={"justify"} fontFamily="Montserrat" style={{paddingBottom:"3px"}}>
                Es nuestra visión ser reconocida por la calidad de nuestro trabajo y por el trato humano hacia nuestros pacientes, por ello ponemos todo nuestro saber y hacer en solucionar de forma satisfactoria los problemas de salud dental.
                </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Grid container direction="column" alignItems="center" justifyContent="center" style={{height:"100%"}}>
                  <img src={"/consultorio3.webp"} style={{maxWidth:"90%",maxHeight:"40vh",borderRadius:"5%"}} alt="" />
                </Grid>
            </Grid>
          </Grid>
          <Divider  style={{marginTop:"10px",marginBottom:"10px"}}/>
        </Grid>
        <Divider/>
      </Grid>

      <Grid container>
      <Grid item xs={12} sx={{ marginBottom:"20px",marginTop:"20px", textAlign:"center"}}>
        <Typography  variant="h4" align="center" fontFamily="Montserrat">
          ¡Conoce a nuestro equipo!
        </Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Box sx={{width:{md:'80%',sm:'100%'}, maxWidth:"100%",marginBottom:"20px"}}>
         <CarouselNosotros/>
        </Box>
      </Grid>

    </Grid>
          
    
    </Grid>

  );
}

export default Nosotros;