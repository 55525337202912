import React, { useState,useEffect } from "react";
import Layout from "./components/Layout";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import NuevaPagina from "./components/servicios";
import Paginainicio from "./components/inicio";
import Nosotros from "./components/nosotros";
import useMediaQuery from '@mui/material/useMediaQuery';
import Contactos from "./components/Contactos";
import Pruebas from "./components/Prueba";
import Reserva from "./components/Reserva";
import Ortodoncia from "./components/servicios/ortodoncia";
import Cirugia from "./components/servicios/cirugia";
import Rehabilitacion from "./components/servicios/rehabilitacion";
import EsteticaDental from "./components/servicios/estetica_dental";
import Operatoria from "./components/servicios/operatoria";
import Peridoncia from "./components/servicios/peridoncia";
import AtencionNinos from "./components/servicios/ninos";
import Ortopedia from "./components/servicios/ortopedia";
import Implantes from "./components/servicios/implante";
import Endodoncias from "./components/servicios/endodoncia";
import Tempomandibular from "./components/servicios/tempomandibular";
import ReactPixel from 'react-facebook-pixel';


function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const selectedTheme = localStorage.getItem('selectedTheme');


  
  const [darkMode, setDarkMode] = React.useState(selectedTheme !== null ? selectedTheme==="true"? true:false : prefersDarkMode);
  const Theme = React.useMemo(() =>
  createTheme({
      palette: {
        mode: darkMode ? "dark" : "light",
        background: {
          default: darkMode? "#313131":"#F4F4F4",//"#212121 ":"#F4F4F4"

        },
        primary: {
          main: darkMode? '#007500':"#008000",
        }
      },
      header: {
        color: darkMode? "#313131":"#F4F4F4",
      },
    })
  );

  useEffect(() => {
    setDarkMode(selectedTheme !== null ? selectedTheme==="true"? true:false : prefersDarkMode);
  }, [prefersDarkMode]);
  useEffect(() => {
    localStorage.setItem('selectedTheme', darkMode);
  }, [darkMode]);
  useEffect(() => {
    // Inicializar el píxel de Facebook
    ReactPixel.init('605688491674524');

    // Rastrear el evento "PageView" en el montaje del componente
    ReactPixel.pageView();
  }, []);
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <div className="App">
      <Router>
        <Layout darkMode={darkMode} setDarkMode={setDarkMode} >   
          <Routes>
            <Route path='*' element={<Paginainicio/>} />
            <Route path='/inicio' element={<Paginainicio/>} />
            <Route path='/servicios' element={<NuevaPagina/>} />
            <Route path='/nosotros' element={<Nosotros/>} />
            <Route path='/contactos' element={<Contactos/>} />
            <Route path='/pruebas' element={<Pruebas/>} />
            <Route path='/agenda tu hora' element={<Reserva/>}/>

            <Route path='/cirugia' element={<Cirugia/>}/>
            <Route path='/rehabilitacion' element={<Rehabilitacion/>}/>
            <Route path='/estetica_dental' element={<EsteticaDental/>}/>
            <Route path='/operatoria' element={<Operatoria/>}/>
            <Route path='/peridoncia' element={<Peridoncia/>}/>
            <Route path='/atencion_nino' element={<AtencionNinos/>}/>
            <Route path='/ortodoncia' element={<Ortodoncia/>}/>
            <Route path='/ortopedia' element={<Ortopedia/>}/>
            <Route path='/implante' element={<Implantes/>}/>
            <Route path='/endodoncia' element={<Endodoncias/>}/>
            <Route path='/tempomandibular' element={<Tempomandibular/>}/>

          </Routes>
        </Layout>
      </Router>

      </div>
    </ThemeProvider>
  );
}




export default App;

