import Grid from '@mui/material/Grid';
import {ButtonBases,EspecialButton} from './ListaServicios';
import React, { useRef, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import "@fontsource/montserrat";

function NuevaPagina() {

  return (
    <Grid container spacing={2} sx={{marginBottom:"20px"}}>
        <Grid item xs={12} sx={{ textAlign:'center',marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" align="center" fontFamily="Montserrat">
                Descubre nuestros servicios
              </Typography>
              <Typography variant="body" fontSize="17px" align="center"  fontFamily="Montserrat">
               En Cedsa Dent, nos esforzamos por brindarte una atención odontológica de calidad con profesionales de excelencia.
              </Typography> 
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} style={{marginBottom:"1%"}}>
            Servicios de Odontología General
                </Typography>
            <ButtonBases/>
          </Grid>
          <Grid item xs={12}md={6}>
            <Typography variant="h5" align="center" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} style={{marginBottom:"1%"}}>
            Servicios de especialidades
                </Typography>
            <EspecialButton/>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default NuevaPagina;