import React, { useState } from 'react';
import './WPButton.css';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import { Popper,Fade,Paper, Typography } from '@mui/material';

const WhatsappButton = () => {
  const [open, setOpen] = React.useState(false);
  const [referenceEl, setReferenceEl] = useState(null);
  const handleOpenPopper = (event) => {
    setOpen(true);
  };
  const handleClosePopper = (event) => {
    setOpen(false);
  };
  const handleChangePopper = (event) => {
    setOpen(!open);
  };

  const fabGreenStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[800],
    },
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: 'primary',
      sx: fabGreenStyle,
      icon: <WhatsAppIcon fontSize='large'/>,
      label: 'WhatsApp',
    }
  ];
  const Mensaje = () => {
    console.log("Mensaje")
  };

  function  redirigirALink () {
    window.location.href ='https://wa.me/56984495193';
  };

  return (
    

      <div className="WhatsappButton">
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
          ref={setReferenceEl}
        >
          <Fab onClick={redirigirALink} 
               onMouseEnter={handleChangePopper}
               onMouseLeave={handleChangePopper}
          sx={fab.sx} aria-label={fab.label} color={fab.color}>
            {fab.icon}
          </Fab>
        </Zoom>
      ))}

       <Popper open={open} anchorEl={referenceEl} 
            placement={'left'} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={150}>
                  <Paper style={{padding:"10px",justifyContent:"center",marginRight:"5px"}}>
                    <Typography variant='body' fontFamily="Montserrat">
                      ¡Escribenos! Tenemos urgencias las 24h
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>

    </div>



    
  );
};

export default WhatsappButton;
