
import React, { useRef, useEffect,useState } from "react";
import '@fontsource/roboto/700.css';
import { ThemeProvider, useTheme } from "@mui/material/styles";
import "@fontsource/montserrat";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from "@mui/material";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {Divider} from "@mui/material";
import {Typography} from "@mui/material";
import {Card} from "@mui/material";
import {CardMedia} from "@mui/material";
import {CardContent} from "@mui/material";
import { CardActionArea } from '@mui/material';
import {Backdrop} from "@mui/material";

function Reserva() {
  const theme = useTheme();
  const bckcolor = theme.palette.primary.light;
  const [isLoading, setIsLoading] = useState(false);
  function ExternalRedirect() {
 
    setIsLoading(true);
    setTimeout(() => {
      window.location.href = 'https://ff.healthatom.io/q6hPPv';  // Reemplaza con la URL externa a la que deseas redirigir
    }, 0); 
  }
    return (
          <Grid container>
            {isLoading ? (
              <div style={{ position: 'absolute', top: '50%', left: '50%' , margin:"-15vh 0 0 -15vh" }}>
                <Backdrop
                  sx={{ color: '#fff',zIndex:5 }}
                  open={true}
                >
                  <CircularProgress size={"30vh"}/>
                </Backdrop>
            </div> ):(<div/>)} 
          <Grid container sx={{p:"10px",marginLeft:{lg:'15%',md:'5%',sm:'0'},marginRight:{lg:'15%',md:'5%',sm:'0%'}}}>
            <Grid item xs={12} sx={{ textAlign:{md:'center',sm:'left'}}}>
              <Box sx={{p:"20px",}}>
                  <Typography variant="h4" align="center" fontFamily="Montserrat" >
                    ¡Agenda una cita con nosotros!
                  </Typography>
              </Box>
            </Grid>
    
            
            <Grid item md={4} sm={12} sx={{paddingRight:"0px"}} alignContent='center'>
            
              <Card raised={true} sx={{height:"100%",width:{md:"90%",sm:"100%"},justifyContent:'center',marginLeft:"auto",marginTop:"auto"}}>
              <CardActionArea onClick={ExternalRedirect}>
              <CardMedia
                  component="img"
                  height="100"
                  image="consultorio1.webp"
                />
                <Box
                  sx={{
                    position: 'relative',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: 'rgba(52, 142, 64, 0.7)',
                    color:"white",
                    padding: '10px',
                  }}
                >
                  <Typography variant="h5" fontFamily="Montserrat">Reserva tu cita con nosotros</Typography>
                </Box>
                <CardContent sx={{textAlign:"center"}}>     
                       
                    <Typography variant="body"   fontFamily="Montserrat">
                    Reserva una cita con nosotros, elige el dia, hora y profesional para atenderte.
                    </Typography>
                    <Divider style={{marginBottom:"5px",marginTop:"5px"} }/>
                    <Typography variant="subtitle2" color="text.primary" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"} >
                      Nuestro horario:
                    </Typography>
                    <Typography component="div" variant="body" color="text.primary" fontFamily={'Montserrat, sans-serif'} >
                      Lunes - Viernes
                    </Typography>
                    <Typography style={{paddingBottom:"4px"}}component="div" variant="body2" color="text.primary" fontFamily={'Montserrat, sans-serif'} >
                      8:00 a 20:00
                    </Typography>
                    <Typography component="div" variant="body" color="text.primary" fontFamily={'Montserrat, sans-serif'} >
                    Sábados.
                    </Typography>
                    <Typography style={{paddingBottom:"4px"}}component="div" variant="body2" color="text.primary" fontFamily={'Montserrat, sans-serif'} >
                    8:00 a 14:00
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"}>
                      Urgencias las 24 horas.
                    </Typography>
                    <Divider style={{marginBottom:"5px",marginTop:"5px"} }/>
                    <Typography variant="caption" align="center" fontFamily="Montserrat" >
                    *Nuestro servicio de consulta presencial tienen un costo de $10.000.
                  </Typography>

                </CardContent>

              </CardActionArea>  
              </Card>
            </Grid>

            <Grid item md={4} sm={12} sx={{paddingRight:"0px"}} alignContent='center'>
              <Card raised={true} sx={{height:"100%",width:{md:"90%",sm:"100%"},justifyContent:'center',marginLeft:"auto",marginTop:"auto"}}>
              <CardActionArea  sx={{height:"100%"}}href='https://wa.me/56939287460'>
              <CardMedia
                  component="img"
                  height="100"
                  image="Teleconsulta.webp"
                />
                <Box
                  sx={{
                    position: 'relative',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: 'rgba(52, 142, 64, 0.7)',
                    color:"white",
                    padding: '10px',
                  }}
                >
                  <Typography variant="h5" fontFamily="Montserrat">Ten una teleconsulta gratuita.</Typography>
                </Box>
                <CardContent sx={{textAlign:"center", height:"100%"}}>     
                    <Typography variant="body"   fontFamily="Montserrat">
                    Ten una teleconsulta con nuestro profesional de forma gratuita.
                    </Typography>

                </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item md={4} sm={12} sx={{paddingRight:"0px"}} alignContent='center'>
              <Card raised={true} sx={{height:"100%",width:{md:"90%",sm:"100%"},justifyContent:'center',marginLeft:"auto",marginTop:"auto"}}>
              <CardActionArea sx={{height:"100%"}}href='https://wa.me/56984495193'>
              <CardMedia
                  component="img"
                  height="100"
                  image="chatwp.webp"
                />
                <Box
                  sx={{
                    position: 'relative',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: 'rgba(52, 142, 64, 0.7)',
                    color:"white",
                    padding: '10px',
                  }}
                >
                  <Typography variant="h5" fontFamily="Montserrat">¿Tienes una duda? ¡Escribenos!</Typography>
                </Box>
                <CardContent sx={{textAlign:"center"}}>     
                    <Typography variant="body"   fontFamily="Montserrat">
                    Si tienes una duda escribe a nuestra linea de atencion:
                    </Typography>
                    <Typography variant="body" component="div" fontFamily="Montserrat" fontWeight={"700"}>
                    +56-984495193
                    </Typography>
                </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Divider/>
          </Grid>
          
              
        
        </Grid>
    
      );


      
}





export default Reserva;