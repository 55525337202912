import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function EsteticaDental() {

    const submenus=[
        {id:1,text:"Carilla cerámica"},
        {id:2,text:"Carilla de resina"},
        {id:3,text:"Blanqueamiento"},
        {id:4,text:"Diseño de sonrisa"},
    ]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Servicios Generales
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" alignItems="center" paddingTop={"16px"}>
            <Grid item xs={4} md={12}>
                <img src={"Estetica.webp"} style={{maxWidth:"100%",widht:"100%",borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
            Odontológica estética            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La odontología estética, también conocida como odontología cosmética o odontología estética, es una rama de la odontología que se enfoca en mejorar la apariencia de los dientes y la sonrisa de una persona. Su objetivo principal es lograr una estética dental óptima, creando una sonrisa atractiva y armoniosa.
            </Typography>
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Carilla cerámica            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una carilla de cerámica es un tipo de restauración dental que se utiliza para mejorar la apariencia estética de los dientes frontales. Consiste en una fina lámina de cerámica que se adhiere a la parte frontal de un diente existente para corregir imperfecciones cosméticas y lograr una sonrisa más atractiva
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las carillas de cerámica están hechas de porcelana o cerámica dental de alta calidad. Estos materiales se eligen por su apariencia similar al esmalte dental natural, su resistencia a las manchas y su durabilidad. La cerámica se puede personalizar para que coincida con el color, la forma y el tamaño de los dientes naturales adyacentes, lo que resulta en una apariencia estética y armoniosa.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las carillas de cerámica pueden corregir una variedad de problemas dentales estéticos, como dientes descoloridos, astillados, desalineados, desiguales o con espacios entre ellos. Además de mejorar la apariencia, las carillas también pueden proporcionar cierta protección a los dientes naturales subyacentes
                    </Typography> 
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Es importante tener en cuenta que las carillas de cerámica requieren cuidado y mantenimiento adecuados, como una buena higiene oral y visitas regulares al dentista. También es posible que se requiera reemplazarlas después de un período de tiempo, ya que pueden desgastarse o dañarse con el tiempo.
                    </Typography>                                    
                </Paper>
            }
            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Carilla de resina           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una carilla de resina, también conocida como carilla de composite, es una restauración dental estética utilizada para mejorar la apariencia de los dientes. Está compuesta de un material de resina compuesta, que es una mezcla de partículas de cerámica y resina sintética.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las carillas de resina se utilizan principalmente para corregir problemas estéticos como dientes manchados, astillados, desalineados o con espacios entre ellos. También pueden utilizarse para cambiar el tamaño o la forma de los dientes.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las carillas de resina pueden ser menos duraderas que las carillas de cerámica y pueden requerir reparaciones o reemplazos con el tiempo. También son más propensas a mancharse o desgastarse en comparación con las carillas de cerámica.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Es importante tener en cuenta que las carillas de resina requieren cuidado y mantenimiento adecuados, como una buena higiene oral y visitas regulares al dentista. También pueden ser susceptibles a cambios de color con el tiempo, especialmente si se consumen alimentos o bebidas que tienden a manchar los dientes, como café, té o vino tinto.
                    </Typography>
                </Paper>
            }
            {selectedOption === 3 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Blanqueamiento            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El blanqueamiento dental es un procedimiento dental estético que se utiliza para aclarar el color de los dientes y mejorar su apariencia. Es un tratamiento no invasivo y popular que se realiza para eliminar las manchas y decoloraciones dentales causadas por diversos factores, como el envejecimiento, el consumo de alimentos y bebidas pigmentadas (como el café, el té o el vino tinto), el tabaquismo y ciertos medicamentos.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Existen dos tipos principales de blanqueamiento dental:
                    </Typography>
                    <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                            <ListItemText 
                            primary={
                                <Typography variant="body" fontFamily="Montserrat">
                                Blanqueamiento dental en consultorio: Este tipo de blanqueamiento se realiza en el consultorio del dentista. El dentista aplicará un gel blanqueador altamente concentrado en la superficie de los dientes y, en algunos casos, puede utilizar una luz especial o láser para activar el gel. La luz o el láser ayudan a acelerar el proceso de blanqueamiento. Por lo general, se necesitan varias sesiones de tratamiento, y cada sesión dura aproximadamente de 30 a 60 minutos.
                                </Typography>
                            }
                            />
                        </ListItem>
                        <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                            <ListItemText 
                            primary={
                                <Typography variant="body" fontFamily="Montserrat">
                                Blanqueamiento dental en el hogar: Este tipo de blanqueamiento se realiza en casa, siguiendo las instrucciones proporcionadas por el dentista. El dentista creará una férula dental personalizada que se ajusta a tus dientes. Luego, te proporcionará un gel blanqueador de menor concentración que se coloca en la férula y se usa durante un período específico cada día. Por lo general, el blanqueamiento en el hogar se realiza durante varias semanas hasta que se logre el resultado deseado.
                                </Typography>
                            }
                            />
                        </ListItem>
                    </List>    
                </Paper>
            }
            {selectedOption === 4 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Diseño de sonrisa           
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El diseño de sonrisa dental es un proceso estético y personalizado que busca mejorar la apariencia de la sonrisa de una persona. Consiste en analizar y planificar los cambios necesarios en la forma, el tamaño, el color y la alineación de los dientes para lograr una sonrisa más armoniosa y atractiva.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El diseño de sonrisa dental es realizado por dentistas especializados en odontología estética o cosmética. Durante el proceso, el dentista evalúa la estructura dental, la forma de los dientes, la línea de la sonrisa, la encía y otros aspectos faciales para determinar los ajustes necesarios.
                    </Typography>
                </Paper>
            }
            
        </Drawer>
    </Grid>
  );
}

export default EsteticaDental;