import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css'; // importamos nuestros estilos personalizados
import Grid  from '@mui/material/Grid';
import { useState } from 'react';
import "@fontsource/montserrat";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Carousels.css";
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


const Carousel = () => {
  const imagesContext = require.context('!!file-loader!../../public', false, /carrousel_\d+\.webp$/);
  const images = imagesContext.keys().map((imagePath, index) => {
    return {
      id: index + 1,
      src: imagesContext(imagePath).default,
      link: null,
    };
  });
  const settings = {
    
    dots: false, // muestra los puntos de navegación
    infinite: true, // permite la navegación infinita
    speed: 2000, // velocidad de transición de una diapositiva a otra
    slidesToShow: 2, // muestra una diapositiva a la vez
    slidesToScroll: 1, // desplaza una diapositiva a la vez
    centerMode: true, // muestra la diapositiva central y parte de las otras a los lados
    centerPadding: '20%',// padding lateral para la diapositiva central 
    autoplay:true,
    autoplaySpeed:4000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              centerPadding: '15%',
              slidesToShow: 2
            },
        },{
            breakpoint: 900,
            settings: {
              centerPadding: '10%',
              slidesToShow: 2
            },
        },{
            breakpoint: 600,
            settings: {
              centerPadding: '5%',
              slidesToShow: 1
            },
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + ' arrow next'}
        style={{ ...style, display: 'flex', fontSize: '40px' }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + ' arrow prev'}
        style={{ ...style, display: 'flex', fontSize: '40px' }}
        onClick={onClick}
      />
    );
  }
  return (
    <div style={{paddingTop:"10px"}}>
      <Slider {...settings}>
        {images.map((image) => (
          <div key={image.id}>
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <img src={image.src} alt="" />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

{/*
const Carousel=() => {
  return (
      <div>
          <Swiper
          slidesPerView={3}
          centeredSlides={true}
          loopedslides={3}
          freeMode= {true}
          freeModeMomentum= {false}
          spaceBetween={10}
          speed={1000}
          loop={true}
          pagination={{
          clickable: true,
          initialSlide: 4,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}

          >
             {images.map((image) => (
              <SwiperSlide key={image.id}>
              {image.link ? (
                    <Link href={image.link} target="_blank" rel="noopener noreferrer">
                      <img src={image.src} alt="" />
                    </Link>
                  ) : (
                    <img src={image.src} alt="" />
                  )}
              </SwiperSlide>
              ))}
          </Swiper>
      </div>

    );
}
*/}

const Slowslidericons= () =>{
  const icons = [
    { id: 1,  src: 'MetLife11.webp',             alt: "MetLife" },
    { id: 2,  src: 'BICEVIDA11.webp',            alt: "BICE VIDA" },
    { id: 3,  src: 'CruzBlanca.webp',          alt: "Cruz Blanca" },
    { id: 4,  src: 'VidaCamara11.webp',          alt: "Vida Cámara" },
    { id: 5,  src: 'Chilenaconsolidada.webp',  alt: "Chilena Consolidada" },
    { id: 6,  src: 'Sura.webp',                alt: "Sura" },
    { id: 7,  src: 'Consorcio.webp',           alt: "Consorcio" },
    { id: 8,  src: 'EuroEuropa.webp',          alt: "EuroAmerica" },
    { id: 9,  src: 'VDASecurity.webp',         alt: "VDA Security" },
  ];
  const settings2 = {
    arrows:false,
    dots: false, // muestra los puntos de navegación
    infinite: true, // permite la navegación infinita
    slidesToShow: 5, // muestra una diapositiva a la vez
    slidesToScroll: 1, // desplaza una diapositiva a la vez
    autoplay: true,
    speed: 3000,
    cssEase: "linear",
    autoplaySpeed: 0,
    mobileFirst:true,
    centerMode:true,
    centerPadding: '20%',
    responsive: [
      {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            centerPadding: '20%',
          }
    },{
            breakpoint: 900,
            settings: {
              slidesToShow: 3,              
              centerPadding: '25%',
            }
      },{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerPadding: '33%',
            }
      }
  ],
  };

  return (
    <Grid item xs={12} alignItems="center" align = "center" justifyContent = "center" style={{ padding: '20px',width:"100vh"}}>
      <Slider {...settings2}>
        {icons.map((icons) => (
          <div title={icons.alt} key={icons.id}>
              <img src={icons.src} alt={icons.alt} style={{maxHeight:"20% !important", paddingInline:"10px" }}
              />
          </div>
        ))}
      </Slider>
    </Grid>

  );


};

const CarouselNosotros=() =>{
  const personal = [
    { id: 1,name:"Dr. Jesús David Diaz Ardila",   
      cargo:"Profesional odontológico, egresado de la Universidad del Norte de Barranquilla, Colombia.", 
      descripcion: 'Tengo una amplia experiencia en el manejo y resolución de urgencias odontológicas, así como en tratamientos pulpares, rehabilitación fija y removible. Además, me caracterizo por mi trato empático y humanizado hacia mis pacientes, brindando un cuidado dental personalizado y enfocado en su bienestar. Estoy comprometido en proporcionar una atención de calidad y establecer una relación de confianza con cada paciente.',
      src: 'doc_diaz.webp' },
    { id: 2,name:"Dra. Javiera Rojas", cargo:"Profesional odontológico, egresada de la Universidad de Concepción.", 
      descripcion: 'Mi objetivo es proporcionar atención dental de alta calidad en un ambiente cálido y acogedor. Me esfuerzo por establecer relaciones de confianza con mis pacientes, escuchando sus preocupaciones y diseñando un plan de tratamiento personalizado que se adapte a sus necesidades y deseos.',
      src: 'doc_carcamo.webp' },
    { id: 3,name:"Dr. Campo Aníbal Jiménez Caballero", cargo:'Especialista en Ortodoncia de la corporación de ortodoncia y ortopedia de Chile. Profesional odontológico, egresado de la Fundación Universitaria San Martin de Colombia.', 
      descripcion: 'Con amplia experiencia en el manejo de pacientes adultos y pediátricos. Me desempeño en todas las áreas de la profesión y cuento con un dominio actualizado de los materiales e instrumentos utilizados en el campo. Poseo un conocimiento profundo en laboratorio dental, con especial énfasis en ortodoncia. Esto me permite realizar procedimientos precisos y de alta calidad, garantizando resultados satisfactorios para mis pacientes. Además de mis habilidades técnicas, destaco por mi capacidad para establecer relaciones interpersonales sólidas. Reconozco la importancia de brindar un trato cálido y empático, creando un ambiente acogedor y de confianza para mis pacientes.'
            ,src: 'doc_campos.webp' },
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const settingsNosotros = {
    fade:false,
    cssEase:'linear',
    dots: false, // muestra los puntos de navegación
    infinite: true, // permite la navegación infinita
    speed: 1000, // velocidad de transición de una diapositiva a otra
    slidesToShow: 1, // muestra una diapositiva a la vez
    slidesToScroll: 1, // desplaza una diapositiva a la vez
    centerMode: true, // muestra la diapositiva central y parte de las otras a los lados
    autoplay:true,
    autoplaySpeed:5000,
    className:'SliderNosotros',

    responsive: [
        {
            breakpoint: 1200,
            settings: {
            },
        },{
            breakpoint: 900,
            settings: {
            },
        },{
            breakpoint: 600,
            settings: {
            },
      }
    ],


    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + ' arrow next'}
        style={{ ...style, display: 'flex', fontSize: '40px' }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + ' arrow prev'}
        style={{ ...style, display: 'flex', fontSize: '40px' }}
        onClick={onClick}
      />
    );
  }
  return (
    <div  style={{width:"100%", maxWidth:"100vh",  marginLeft: 'auto',
    marginRight: 'auto'}}>
      <Slider {...settingsNosotros}>
        {personal.map((personal) => (
          <div key={personal.id} className='slide' style={{width:"100%"}}>
            <Accordion style={{backgroundColor:"inherit", width:"100%",height:"100%"}}>
              <AccordionSummary >
                <Grid container  >
                <Grid item sm={12} md={6}>
                <img src={personal.src} 
                  alt="" />
              </Grid>

                <Grid item sm={12} md={6} direction={"column"} display={"flex"} justifyContent={"center"}>
                  <Typography variant='subtitle' fontFamily="Montserrat" className='name' fontWeight={"700"}>{personal.name}</Typography>
                  <Typography variant='body2' fontFamily="Montserrat" className='cargo'>{personal.cargo}</Typography>
                </Grid>
                </Grid>


              </AccordionSummary>
              <AccordionDetails style={{backgroundColor:"rgba(255,255,255,0.5)"}}>
                <Typography variant='body2' fontFamily="Montserrat" className='descripcion'>{personal.descripcion}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Slider>
    </div>
  );
};





export { Slowslidericons, Carousel,CarouselNosotros };
export default Carousel;
