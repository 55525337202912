import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function Endodoncias() {

  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'0'},paddingRight:{lg:'15%',md:'5%',sm:'0%'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
              Servicios de especialidades
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3}   justifyContent="center"   alignItems="center">
            <img src={"endodoncia.webp"} style={{maxWidth:"100%",borderRadius:"10px"}} alt="" />
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
            Endodoncias            
            </Typography>
            <Typography variant="h6" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}style={{marginBottom:"10px"}}>
            Tratamiento de conductos en piezas anteriores y posteriores            
            </Typography>

            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Una endodoncia, también conocida como tratamiento de conducto, es un procedimiento dental que se realiza cuando la pulpa dental, el tejido blando en el interior del diente, se infecta o se inflama debido a una caries profunda, una lesión en el diente o una enfermedad periodontal.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            Durante una endodoncia, se elimina la pulpa dental dañada o infectada, se limpian los conductos radiculares (las estructuras que contienen la pulpa) y se sellan para prevenir la reinfección. El objetivo principal del tratamiento de conducto es salvar el diente y aliviar el dolor o la incomodidad que el paciente pueda experimentar debido a la infección o inflamación.
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default Endodoncias;