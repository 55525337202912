import React from 'react';
import {Slowslidericons, Carousel} from "./Carousel";
import GroupButtons from './GroupButtons';
import Paper from '@mui/material/Paper';
import '@fontsource/roboto/700.css';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import "./inicio.css";
import "@fontsource/montserrat";
import Contactos from './Contactos';
import { NavLink } from 'react-router-dom';

const icons = [
  { id: 1,  src: 'MetLife11.webp',             alt: "MetLife" },
  { id: 2,  src: 'BICEVIDA11.webp',            alt: "BICE VIDA" },
  { id: 3,  src: 'CruzBlanca.webp',          alt: "Cruz Blanca" },
  { id: 4,  src: 'VidaCamara11.webp',          alt: "Vida Cámara" },
  { id: 5,  src: 'Chilenaconsolidada.webp',  alt: "Chilena Consolidada" },
  { id: 6,  src: 'Sura.webp',                alt: "Sura" },
  { id: 7,  src: 'Consorcio.webp',           alt: "Consorcio" },
  { id: 8,  src: 'EuroEuropa.webp',          alt: "EuroAmerica" },
  { id: 9,  src: 'VDASecurity.webp',         alt: "VDA Security" },
];


function Paginainicio() {

  const [isSeguro, setSeguro] = useState(false);

  function handleClick() {
    setSeguro(!isSeguro);
  }
  return (
    <Grid style={{alignItems:"stretch", align :"center", justifyContent : "center"}}>
    <Carousel></Carousel>
    <NavLink to="/agenda tu hora" style={{color:"inherit",textDecoration:"inherit"}}>
      <Grid style={{backgroundColor:"rgba(100, 192, 100, 1)",display:"flex"}} justifyContent={"center"} alignContent={"center"}>
       
          <Typography variant="h5" color="white" fontFamily="Montserrat"
              style={{height:"40px",alignContent:"center",display: "flex",flexWrap:"wrap",marginBottom:"10px",marginTop:"10px"}}>
              ¡Agenda con nosotros!
          </Typography>
        

      </Grid>
      </NavLink>
    <Grid container
       alignItems="center" align = "center" justify = "center" paddingRight={0} 
       paddingLeft={0} paddingTop="1%" paddingBottom="1%" 
       >    
         <Paper elevation={0} style={{mr:"10px"}} 
          sx={{display:"flex",width:"100%", border: "1px",  borderRadius: '5px',marginRight:{sm:"1%",md:"5%"},marginLeft:{sm:"1%",md:"5%"},background:"none"}}>
         <GroupButtons/>
         </Paper>
      </Grid>
      <Divider sx={{marginBottom:"10px"}}/>
      <Grid style={{backgroundColor:"rgb(0, 139, 156)"}} justifyContent={"center"} alignContent={"center"}>
      <Accordion style={{backgroundColor:"inherit", width:"100%"}}>
        <AccordionSummary >
          <Typography variant="subtitle1" fontSize={"19px"} color="white" fontFamily="Montserrat"
            style={{height:"40px",alignContent:"center",display: "flex",flexWrap:"wrap"}}>
            Reembolsa tu dinero con tu seguro complementario
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:"rgba(255,255,255,0.5)"}}>
        <Grid container columns={18} justifyContent={"center"}>
            <Grid item xs={18} justifyContent={"center"}>
              <Typography variant="subtitle" color={"black"} style={{ margin:"2px",justifyContent:"center",display:"flex"}} >
                Estos son los seguros con los que trabajamos:
              </Typography>
              <Divider style={{margin:"10px"}}/>
            </Grid>
              {icons.map((icons) => (
                <Grid item xs={6} sm={4} md={3} lg={2} title={icons.alt} key={icons.id}>
                    <img src={icons.src} alt={icons.alt} style={{width:"90%",textAlign:'center', padding:'2px',borderRadius: '15%'}} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      </Grid>

      <Divider sx={{marginTop:"10px"}}/>
      
      <Contactos/>
    </Grid>


  );
}

export default Paginainicio;