import Grid from '@mui/material/Grid';
import React, { useRef, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import '@fontsource/roboto/700.css';
import SocialButtons from './Redes';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import "@fontsource/montserrat";

function Contactos() {
  const theme = useTheme();
  const bckcolor = theme.palette.primary.light;
    return (
      <Grid style={{padding:'10px',mr:"10px"}}>
        <Grid container alignItems="stretch" align = "center" justify = "center"  >
          <Grid item xs={12} sx={{paddingTop:"10px"}}>
          <Typography variant="h4" m={2} fontFamily={'Montserrat, sans-serif'}>
          ¡Contáctanos!
          </Typography>

          </Grid>

          <Grid container direction='row' spacing={0} alignItems="stretch" 
            sx={{ border: 1,  borderRadius: '5px',marginRight:{sm:"1%",md:"1%",lg:"10%"},marginLeft:{sm:"1%",md:"1%",lg:"10%"} }}
          >
          <Grid item xs={12} md={5} align = "center" justify = "center" alignItems = "center"
         >
            <Contacts/>
          </Grid>
            <Grid item xs={12} md={7} align = "center" justify = "center" alignItems = "center">
              <MapIframe/>
            </Grid>
        </Grid>
          </Grid>

      </Grid>

      );
}


const MapIframe = () => {
    return (
      <Card sx={{ maxWidth: '100%', borderTopLeftRadius:"0",borderBottomLeftRadius:"0"}}>
      <CardMedia>
        <div className="map-container">
          <iframe
            className="map-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.0481030534575!2d-68.92660488504237!3d-22.464826585238356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96ac09451a654517%3A0xbb61fcb31a8c8352!2sCEDSADENT!5e0!3m2!1ses-419!2sco!4v1683695956627!5m2!1ses-419!2sco"
            width="100%"
            height="450"
            style={{ border: '0' }}
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
        </div>
      </CardMedia>

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"}>
        Cedsa Dent Villegas EIRL
        </Typography>
        <Typography variant="body2" color="text.secondary">
        </Typography>

      </CardContent>
    </Card>


    );
  };

  const Contacts = () => {
    return (   
      <Paper sx={{display: 'flex',width: '100%',height: "100%", display: "flex", alignItems: "stretch", p:"15px", backgroundColor:"inherit"}}>

        <Grid container rowSpacing={{xs: 5, sm: 5, md: 3}} alignItems="center" align = "center" justify = "center"  >

        <Grid item xs={12} md={6} align = "center" justify = "center" alignItems = "center" >
          <Link to={'https://goo.gl/maps/dk5fCFECeAcqpBZ47'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Box sx={{padding:"10px"}}>
              <LocationOnIcon fontSize="large" />
              <Typography gutterBottom variant="h6" component="div" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"}>
                DIRECCION:
              </Typography>
              <Typography variant="body" color="text.secondary">
                Vivar 1510, esquina Pedro León Gallo. Centro.
              </Typography>
            </Box>
          </Link>
        </Grid>

        <Grid item xs={12} md={6} align = "center" justify = "center" alignItems = "center">
        <Link to={'tel:+56984495193'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <Box sx={{padding:"10px"}}>
            <PhoneInTalkIcon fontSize="large" />
            <Typography gutterBottom variant="h6" component="div" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"}>
              TELEFONO:
            </Typography>
            <Typography variant="body" color="text.secondary">
              +56-984495193
            </Typography>
          </Box>
          </Link>
        </Grid>

        <Grid item xs={12} md={6} align = "center" justify = "center" alignItems = "center">
        <Link to={'https://wa.me/56939287460'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <Box sx={{padding:"10px"}}>
            <ContactPhoneIcon fontSize="large" />
            <Typography gutterBottom variant="h6" component="div" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"}>
              TELECONSULTAS:
            </Typography>
            <Typography variant="body" color="text.secondary">
              +56-939287460
            </Typography>
          </Box>
          </Link>
        </Grid>

        <Grid item xs={12} md={6} align = "center" justify = "center" alignItems = "center">
          <Box sx={{padding:"10px"}}>
            <Typography gutterBottom variant="h5" component="div">
              
            </Typography>
            <Typography variant="h5" color="text.primary"  fontFamily={'Montserrat, sans-serif'} >
              Nuestro horario:
            </Typography>
            <Typography component="div" variant="body"  color="text.primary" fontFamily={'Montserrat, sans-serif'} >
              Lunes - Viernes
            </Typography>
            <Typography style={{paddingBottom:"4px"}}component="div"  variant="body2" color="text.primary" fontFamily={'Montserrat, sans-serif'} >
              8:00 a 20:00
            </Typography>
            <Typography component="div" variant="body" color="text.primary"   fontFamily={'Montserrat, sans-serif'} >
            Sábados.
            </Typography>
            <Typography style={{paddingBottom:"4px"}}component="div" variant="body2" color="text.primary" fontFamily={'Montserrat, sans-serif'} >
            8:00 a 14:00
            </Typography>
            <Typography variant="subtitle1" color="text.primary" fontFamily={'Montserrat, sans-serif'} fontWeight={"700"}>
              Urgencias las 24 horas.
            </Typography>
          </Box>

        </Grid>       
        
        </Grid>

      </Paper>

      );
  };




export default Contactos;