import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function Operatoria() {

    const submenus=[
        {id:1,text:"Restauraciones "},
        {id:2,text:"Incrustaciones"},
    ]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Servicios Generales
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" alignItems="center" paddingTop={"16px"}>
            <Grid item xs={4} md={12}>
                <img src={"operatoria1.webp"} style={{maxWidth:"100%",widht:"100%",borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
            Operatoria            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La operatoria dental, también conocida como odontología restauradora, es una rama de la odontología que se ocupa de la prevención, diagnóstico y tratamiento de las enfermedades y lesiones dentales, así como de la restauración de los dientes dañados o deteriorados.
            </Typography>
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Restauraciones             
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una restauración dental es un procedimiento en el campo de la odontología que se realiza para reparar y restaurar la función y la apariencia de un diente dañado, decayente o comprometido. Estas restauraciones se utilizan para corregir problemas dentales como caries, fracturas o desgaste dental. 
                    </Typography>                                  
                </Paper>
            }
            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Incrustaciones            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una incrustación dental, también conocida como inlay o onlay, es una restauración dental utilizada para reparar una cavidad o una parte dañada de un diente. A diferencia de las obturaciones (empastes), que se colocan directamente en el diente en el consultorio del dentista, las incrustaciones dentales se fabrican en un laboratorio dental a medida y luego se cementan en su lugar.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Las incrustaciones dentales se utilizan cuando la caries dental o el daño en el diente son demasiado grandes para ser reparados con una obturación tradicional, pero no requieren la colocación de una corona dental completa. Son una opción más conservadora que las coronas y permiten preservar más estructura dental natural.
                    </Typography>
                </Paper>
            }
            
        </Drawer>
    </Grid>
  );
}

export default Operatoria;