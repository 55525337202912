import Grid from '@mui/material/Grid';
import React from "react";
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import "@fontsource/montserrat";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';

function Peridoncia() {

    const submenus=[
        {id:1,text:"Limpieza dental"},
        {id:2,text:"Destartraje subgingival y supragingival"}
    ]
    const [selectedOption, setSelectedOption] = React.useState(null);
    const handleOptionClick = (Option) => {
        setSelectedOption(Option);
        };


  return (
    <Grid container spacing={2} sx={{paddingLeft:{lg:'15%',md:'5%',sm:'3px'},paddingRight:{lg:'15%',md:'5%',sm:'3px'}}}>
        <Grid item xs={12} sx={{marginBottom:{md:"0",sx:"1%"},marginTop:{md:"0",sx:"1%"}}}>
          <Box sx={{paddingTop:"30px",width:"100%"}}>
             <Typography variant="h4" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
                Servicios Generales
              </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{margin:"1%"}}>
          <Grid container xs={12} md={3} justifyContent="center" alignItems="center" paddingTop={"16px"}>
            <Grid item xs={6} md={12}>
                <img src={"peridoncia.webp"} style={{maxWidth:"100%",widht:"100%",borderRadius:"10px"}} alt="" />
            </Grid>
            <Grid item xs={6} md={12}>
                <img src={"peridoncia2.webp"} style={{maxWidth:"100%",widht:"100%",height: 'auto',borderRadius:"10px"}} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12}md={9}>
            <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"}>
            Periodoncia            
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            La periodoncia dental es una rama de la odontología que se enfoca en el diagnóstico, tratamiento y prevención de las enfermedades periodontales, es decir, las enfermedades que progresan los tejidos que rodean y soportan los dientes, como las encías, el hueso alveolar y el ligamento periodontal.
            </Typography>
            <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
            El objetivo principal de la periodoncia es mantener la salud de las encías y los tejidos periodontales, así como prevenir y tratar las enfermedades periodontales. Estas enfermedades pueden incluir la gingivitis (inflamación de las encías) y la periodontitis (inflamación más grave que puede llevar a la pérdida de tejido y hueso que sostienen los dientes).
            </Typography>
            
            <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                {submenus.map((submenu) => (
                <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                    <ListItemButton onClick={()=>handleOptionClick(submenu.id)}>
                        <Typography variant="body" fontFamily="Montserrat"fontWeight={700} color={"#008000"}>
                            {submenu.text}
                        </Typography>                    
                    </ListItemButton> 
                    </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Drawer sx={{width:"40%"}} anchor="right"  open={selectedOption !== null} onClose={() => handleOptionClick(null)}>
            {selectedOption === 1 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Limpieza dental            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Una limpieza dental, también conocida como profilaxis dental, es un procedimiento dental preventivo realizado para eliminar la placa bacteriana, el sarro y las manchas de los dientes y encías. Se recomienda realizar una limpieza dental regularmente, generalmente cada seis meses, como parte de una buena higiene oral y para mantener una salud bucal óptima.
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    Durante la limpieza dental, el dentista también puede brindarte consejos y recomendaciones sobre la técnica de cepillado y el uso adecuado del hilo dental, así como proporcionarte información sobre cómo mejorar tu higiene oral en general.
                    </Typography>
                                                        
                </Paper>
            }

            {selectedOption === 2 && 
                <Paper sx={{width:{xs:"60vw",md:"30vw"},height:"100%",p:"5%",paddingTop:"3vw"}}>
                    <Typography variant="h5" fontFamily="Montserrat" fontWeight={700} color={"rgb(0 122 137)"} sx={{paddingBottom:"3%"}}>
                    Destartraje subgingival y supragingival            
                    </Typography>
                    <Typography variant="body" align={"justify"} paragraph="true"	fontFamily="Montserrat" >
                    El destartraje subgingival y supragingival son técnicas utilizadas en la limpieza dental para eliminar la placa y el sarro tanto por encima como por debajo de la línea de las encías.
                    </Typography>
                    <List dense={true} sx={{ listStyle: "decimal", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                            <ListItemText 
                            primary={
                                <Typography variant="body" fontFamily="Montserrat">
                                Destartraje subgingival: Esta técnica implica la eliminación de la placa bacteriana y el sarro que se han acumulado debajo de la línea de las encías, en las bolsas periodontales. Las bolsas periodontales son espacios anormales entre las encías y los dientes que se forman debido a la enfermedad periodontal (enfermedad de las encías). Para realizar el tratamiento subgingival, se utilizan instrumentos especiales, como curetas periodontales, que se insertan debajo de las encías para raspar y eliminar la placa y el sarro.
                                </Typography>
                            }
                            />
                        </ListItem>
                        <ListItem sx={{ display: "list-item", fontFamily:"Montserrat",paddingBottom:"0",paddingTop:"0"}}>
                            <ListItemText 
                            primary={
                                <Typography variant="body" fontFamily="Montserrat">
                                Destartraje supragingival: Esta técnica se enfoca en la eliminación de la placa y el sarro que se encuentran en la superficie de los dientes, por encima de la línea de las encías. Es la forma más común de limpieza dental y se realiza durante la visita de rutina al dentista. El higienista dental utiliza instrumentos como raspadores y ultrasonidos para raspar suavemente la placa y el sarro de los dientes y las áreas cercanas a las encías.
                                </Typography>
                            }
                            />
                        </ListItem>
                    </List>    
                </Paper>
            }
            
        </Drawer>
    </Grid>
  );
}

export default Peridoncia;